import {
  useRef,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState
} from 'react'

import {
  Configuration,
  ConfigurationParameters,
  NewSessionData,
  NewSessionRequest,
  StreamingAvatarApi
} from '@heygen/streaming-avatar'

import RVideo from './recorder/Rvideo'
import get from 'lodash/get'
import has from 'lodash/has'
import BackgroundRemover from './BackgroundRemover'
import jsLogger from 'js-logger'

export interface IHeyGenConnect {
  say: (text: string) => void
  stop: () => Promise<void>
  cancel: () => void
  play: () => void
}

type Props = {
  onAvatarPlayingFinished: () => void
  onAvatarStartTalking: (latency: number) => void
  thereWasAnError?: string
  setThereWasAnError?: (v: string) => void
  onSessionStarted: () => void
  isRecording: boolean
  permissionsGranted: boolean
  handleChunk?: (videoBlob: Blob, mimeType: string, role: 'avatar') => void
  setDuration: (v: number) => void
  handleVideoClick: () => void
  heygenKey: string
  avatarId?: string
  voiceId?: string
}

const HeyGenConnect: ForwardRefRenderFunction<IHeyGenConnect, Props> = (
  {
    onAvatarPlayingFinished,
    thereWasAnError,
    onAvatarStartTalking,
    setThereWasAnError,
    onSessionStarted,
    isRecording,
    permissionsGranted,
    handleChunk,
    setDuration,
    handleVideoClick,
    heygenKey,
    avatarId,
    voiceId
  },
  ref
) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoMutedRef = useRef<HTMLVideoElement>(null)
  const videoRecorderRef = useRef<RVideo>()
  const streamHasBeenSet = useRef<boolean>(false)
  const startTalkTimestamp = useRef<number>(0)
  const permissionsGrantedRef = useRef<boolean>(false)

  const [stream, setStream] = useState<MediaStream>()
  const [initialized, setInitialized] = useState(false) // Track initialization
  const [data, setData] = useState<NewSessionData>()

  const avatar = useRef<StreamingAvatarApi | null>(null)

  useImperativeHandle(ref, () => ({
    say: (t: string) => {
      jsLogger.log('say', t)
      startTalk(t)
    },
    cancel: cancelTalking,
    stop: stopSession,
    play
  }))

  const play = () => {
    if (!streamHasBeenSet.current) {
      playVideo()
    }
  }

  useEffect(() => {
    if (permissionsGranted && stream) {
      permissionsGrantedRef.current = true
      // setTimeout(playVideo, 500)
      playVideo()
    }
  }, [permissionsGranted, stream])

  useEffect(() => {
    const init = async () => {
      jsLogger.log('HEYGEN: Initializing with Access Token:', heygenKey)
      const hgConfig: ConfigurationParameters = {
        accessToken: heygenKey,
        jitterBuffer: 200
      }
      avatar.current = new StreamingAvatarApi(new Configuration(hgConfig))
      avatar.current.addEventHandler('avatar_start_talking', () => {
        const latency = Date.now() - startTalkTimestamp.current
        onAvatarStartTalking && onAvatarStartTalking(latency)
      })
      avatar.current.addEventHandler(
        'avatar_stop_talking',
        onAvatarPlayingFinished
      )
      setInitialized(true) // Set initialized to true
      startSession()
    }
    init()

    return () => {
      endSession()
    }
  }, [])

  async function endSession () {
    if (!initialized || !avatar.current) {
      return
    }
    await avatar.current.stopAvatar(
      { stopSessionRequest: { sessionId: data?.sessionId } },
      (s: string) => jsLogger.log('stop result', { s })
    )
    setStream(undefined)
    if (videoRecorderRef.current) {
      videoRecorderRef.current.stop()
    }
  }

  const cancelTalking = () => {}

  const startTalk = async (msg: string) => {
    if (avatar.current) {
      startTalkTimestamp.current = Date.now()
      const response = await avatar.current
        .speak({ taskRequest: { text: msg, sessionId: data?.sessionId } })
        .catch(e => {
          jsLogger.error(e.message)
        })

      jsLogger.log('hg start talk response', response)
    }
  }

  const playVideo = () => {
    jsLogger.log(
      '%cplayVideo, permisssions granted',
      'color: gold;',
      permissionsGrantedRef.current
    )
    // const v = permissionsGranted ? videoRef.current : videoMutedRef.current
    if (!permissionsGrantedRef.current) {
      jsLogger.log('playVideo: permissions are not granted, exit playVideo')
      return null
    }
    const v = videoRef.current
    // const v = videoRef.current
    if (stream && v) {
      if (!videoRecorderRef.current) {
        videoRecorderRef.current = new RVideo(
          stream,
          (v: number) => setDuration(v),
          onNewChunk
        )
      }
      jsLogger.log('pause current video')
      v.pause()

      v.srcObject = stream
      if (!permissionsGrantedRef.current) {
        v.autoplay = false
        v.loop = false
      }
      // v.muted = false
      streamHasBeenSet.current = true
      // v.muted = false
      const playPromise = v.play()
      if (v.paused && has(playPromise, 'next')) {
        v.play()
          .then(() => {
            jsLogger.log('--> Video Play success')
          })
          .catch(e => jsLogger.log('--> Video Play error', e.message))
      } else {
        jsLogger.log('Video is not paused')
      }
      if (permissionsGrantedRef.current) {
        onSessionStarted()
      }
      // startSession(sessionIdRef.current, sdpRef.current)
    } else {
      jsLogger.warn('playVideo stream or videoref is not ready')
    }
  }

  const setVideoElement = async (stream: MediaStream) => {
    jsLogger.log('setVideoElement', stream.id)
    jsLogger.log(
      'setVideoElement: permissions granted',
      permissionsGrantedRef.current
    )
    playVideo()
  }

  const onEvent = (e: string) => {}

  const startSession = async () => {
    // setIsLoadingSession(true);
    // await updateToken();
    if (!avatar.current) {
      jsLogger.error('Avatar API is not initialized')
      return
    }

    const newSessionRequest: NewSessionRequest = {
      quality: 'high'
    }
    if (avatarId) {
      newSessionRequest.avatarName = avatarId
    }
    if (voiceId) {
      newSessionRequest.voice = {
        voiceId
      }
    }
    try {
      const res = await avatar.current.createStartAvatar(
        {
          newSessionRequest
        },
        (s: string) => jsLogger.log('createStartAvatar res', { s })
      )
      setData(res)
      setStream(avatar.current.mediaStream)
      setVideoElement(avatar.current.mediaStream)
    } catch (error) {
      console.error('Error starting avatar session:', error)
      jsLogger.error('There was an error starting the session.')
      throw error
    }
    // setIsLoadingSession(false)
  }

  const stopSession = async () => {
    // const sessionId = sessionIdRef.current
    // if (videoRecorderRef.current) {
    //   videoRecorderRef.current.stop()
    // }
    // if (sessionId) {
    //   _stopSession(sessionId)
    // }
  }

  const onNewChunk = (blob: Blob) => {
    if (videoRecorderRef.current) {
      const mimeType = videoRecorderRef.current.mimeType
      if (mimeType) {
        handleChunk && handleChunk(blob, mimeType, 'avatar')
      }
    }
  }

  return (
    <div
      className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative'
      onClick={handleVideoClick}
    >
      {permissionsGranted ? (
        <>
          <video
            key={'heygen_video'}
            id='heygen_video'
            ref={videoRef}
            className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0 bg-blackAlpha-800'
            playsInline
            controls={false}
            autoPlay={false}
            muted={false}
            onAbort={() => jsLogger.log('Video: onAbort')}
            onError={e => jsLogger.log('Video: onError', get(e, 'message'))}
          />
          <BackgroundRemover key={'heygenVideo'} videoRef={videoRef} />
        </>
      ) : (
        <>
          <video
            key={'muted_video'}
            id='muted_video'
            src='avatar_initial.mp4'
            poster='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/avatar_initial_bg.png?alt=media&token=3653cda3-365d-4ddc-9e4b-2160bd949f7b'
            ref={videoMutedRef}
            className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0'
            playsInline
            autoPlay
            loop
            muted
            onAbort={() => jsLogger.log('Muted Video: onAbort')}
            onError={e =>
              jsLogger.log('Muted Video: onError ' + get(e, 'message'))
            }
          />
          <BackgroundRemover key={'mutedVideo'} videoRef={videoMutedRef} />
        </>
      )}

      {/* {!permissionsGranted && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-gray-700 w-full h-full' />
      )} */}
      {isRecording && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-black/60 w-full h-full' />
      )}
      {thereWasAnError && (
        <div className='absolute bottom-0 left-0 bg-white'>
          <p className='text-red'>{thereWasAnError}</p>
        </div>
      )}
      {
        // voiceDetectorEnabled && (
        // <VoiceDetector
        //   streamRef={streamRef}
        //   onVoiceDetected={onVoiceDetected}
        // />
        // )
      }
    </div>
  )
}

export default forwardRef(HeyGenConnect)
