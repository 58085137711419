import { FC } from 'react'

type Props = {
  error: string
}

const ErrorPage: FC<Props> = ({ error }) => {
  return <span>{error}</span>
}

export default ErrorPage
