import EventEmitter from 'events'
import { Socket, io } from 'socket.io-client'
import { ServerToClientEvents, ClientToServerEvents } from 'types/back'

export enum ConnectionState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}

export const enum BackEvent {
  Open = 'open',
  Close = 'close',
  Error = 'error'
}

export class BackClient extends EventEmitter {
  private _socket: Socket<ServerToClientEvents, ClientToServerEvents> | null =
    null
  private _interactionId: string | null = null
  private _noVideo = false

  constructor () {
    super()
    this.connect()
  }

  private connect = () => {
    console.log('Back: connect')
    if (this._socket) {
      this._socket.disconnect()
      this._socket = null
    }

    const isLocalhost =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'

    const serverUrl = isLocalhost
      ? 'http://localhost:3001'
      : 'https://back.facefile.co:3001'
    this._socket = io(serverUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      transports: ['websocket']
      // forceBase64: true
    })

    this._bindSocketEvents()
  }

  private _bindSocketEvents (): void {
    if (this._socket) {
      this._socket.on('connect', () => {
        console.log('socket connected')
        this.emit(BackEvent.Open, this)
        const isLocalhost =
          window.location.hostname === 'localhost' ||
          window.location.hostname === '127.0.0.1'
        if (this._socket) {
          this._socket.emit(
            'interactionStarted',
            this._interactionId,
            isLocalhost,
            this._noVideo
          )
        }
      })

      this._socket.on('disconnect', () => {
        console.log('%cTranscriber is disconnected')
      })
    }
  }

  public sendLog = (messages: any[], context: object) => {
    if (this._socket) {
      this._socket.emit(
        'log',
        this._interactionId,
        messages,
        context,
        Date.now()
      )
    }
  }

  public sendVideoChunk = (
    chunk: Blob,
    mimeType: string,
    role: 'avatar' | 'user'
  ) => {
    if (this._socket) {
      try {
        this._socket.emit(
          'videoChunk',
          this._interactionId,
          chunk,
          mimeType,
          role
        )
      } catch (e) {
        console.error('send video chunk error', e)
      }
    }
  }

  public interactionStarted = (interactionId: string, noVideo: boolean) => {
    this._interactionId = interactionId
    this._noVideo = noVideo
    const isLocalhost =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    if (this._socket) {
      this._socket.emit(
        'interactionStarted',
        interactionId,
        isLocalhost,
        noVideo
      )
    }
  }

  public disconnect (): void {
    if (this._socket) {
      this._socket.emit('interactionEnd', this._interactionId)
      this._socket.disconnect()
      this._socket.close()
    }
  }
}

let cl: BackClient | null = null

export const getBackClient = () => {
  if (cl) {
    return cl
  } else {
    console.log('createing new BackClient')
    cl = new BackClient()
    return cl
  }
}
